// eslint-disable-next-line no-redeclare,no-unused-vars
function rand() {
  return Math.ceil(Math.random() * 10000);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function countDecimals(value) {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  return value.toString().split('.')[1].length || 0;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function decimalSpots(value) {
  if (!isDecimal(value)) return 0;
  var string = value.toString();
  return string.substr(string.indexOf('.') + 1).length;
}

// own round - js bug: Math.round(38.675)
// eslint-disable-next-line no-redeclare,no-unused-vars
function round(value, precision) {
  if (!isDecimal(value)) return value;
  if (!precision) precision = 0;

  var factor = Math.pow(10, precision);
  value *= factor;
  var spots = decimalSpots(value);

  if (spots == 1) value = Math.round(value);
  else if (spots > 1) {
    value *= Math.pow(10, spots);
    for (var i = 0; i < spots; i++) value = Math.round(value / 10);
  }

  value /= factor;
  return value;
}
